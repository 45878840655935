// AboutPage.js
import React from 'react';
import Hero from './img/Lord Toph Biography & Creative Repertoire Image.png'
const AboutBiographyPage = () => (
    <div className="content">
        <img className="hero-img animate__animated animate__fadeIn" src={Hero} alt={"Lord Toph Biography and Creative Repertoire"}/>
        <h1>Lord Toph Biography</h1>
            <p>Lord Toph has integrated art, music, and literature to serve within a fully functional production and publishing entity, catering works and services to an expansive and diversified market. Through each aspect and sub-entity of Monté CrisToph Multimedia, Lord Toph has painstakingly planned and developed a creative and multifaceted entity to appeal to a vast industry. Aside from his extensive body of work in art, music, and literature, he has consistently broadened the product spectrum in each branch of his company.</p>
            <p>An accomplished visual artist, Lord Toph began painting and drawing at the age of three and continued throughout his formative years. He has had many exhibitions of his works prior to and succeeding his formal training as a visual artist (private showings, group shows, solo exhibitions, etc.).</p>
            <p>His work has been sold and shipped in the United States, Germany, Canada, Australia, and South Korea. He has sold several works, and his art has been published in an array of magazines, news articles, and periodicals.</p>
            <h2>Creative Repertoire</h2>
            <p>As the founder of the art style Empathicism, Lord Toph has created hundreds of works that speak to the depths of the human experience. Each brushstroke is imbued with emotion, and each canvas is a window into the soul of its creator.</p>
            <p>As an author, Lord Toph has written several books in various genres ranging from short stories to novel-length works.</p>
            <p>With StarField Stories, which he founded in 2014 with former founding partner Sunghee Lee, Lord Toph has worked as an author, illustrator, designer, and composer to create a profitable and educational framework of strong, relatable characters, content, and themes. StarField Stories' concept, consistency, and integral structure of "character mythology" is geared toward creating a new "merchandising flagship" for a new generation of children's literature. Animation and film are the future goals for this children's literature company.</p>
            <p>As a producer, Lord Toph has produced numerous songs for independent artists that have been part of the Monté CrisToph Music roster. He has written and composed for Folk Acoustic artist Damen Samuel, Adult Contemporary artists Ebony Anne Isaac, Cris Vela, and Kyle Whitney, Hip Hop artist SnowFlake Black, along with Classic Blues/Rock act The Tony Mazza Project, and several other independent artists.</p>
            <p>In 2012, he was first recognized by the American Music Academy and received Grammy candidacy as a producer and songwriter. He was selected as a Grammy candidate again the following year, along with select artists on the Monté CrisToph Music label, and was recognized in several categories for achievements in songwriting, performance, and collaboration. As a BMI music affiliate, Lord Toph has an extensive catalog of music which he has composed, arranged, and produced. Combining his music works published and unreleased, Lord Toph's music catalog well exceeds 950 compositions.</p>
            <p>His compositions traverse genres including pop, rock, gothic rock, soul, R&B, funk, alternative, instrumental, and ambient. Each piece is a testament to his versatility and his innate ability to weave intricate melodies with compelling narratives. As a performer, Lord Toph's powerful vocal range and charismatic stage presence leave an indelible mark on every audience.</p>
        <br />
    </div>
);

export default AboutBiographyPage;
