import React from 'react';

const Time_video = () => (
    <div className="content">
        <h1>Time I Tell You Video Project</h1>

        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/3_the_time_i_tell_you_video_project/The Time I Tell You Video Project - Page 1.png" alt={"The Time I Tell You Video Page 1"}/>
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/3_the_time_i_tell_you_video_project/The Time I Tell You Video Project - Page 2.png" alt={"The Time I Tell You Video Page 2"}/>
        </div>


<div className={"about-text"}>

    </div>
    </div>
);


export default Time_video;