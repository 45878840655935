import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure your CSS file is imported
import image1 from './img/Related Acts - Panel 1 (with Text).png';
import image2 from './img/Related Acts - Panel 2 (with Text).png';
import image3 from './img/Related Acts - Panel 3 (with Text).png';

const images = [
    { src: image1, title: 'Panel 1 Lord Toph Related Artists' },
    { src: image2, title: 'Panel 2 Lord Toph Related Artists' },
    { src: image3, title: 'Panel 3 Lord Toph Related Artists' },
];

const AboutRelatedActsPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="content relatedacts">
            <div className="slider hero-img">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        className={`slide ${index === currentIndex ? 'active' : ''}`}
                        alt={`Panel ${index + 1}`}
                    />
                ))}
            </div>
            <h1>Lord Toph Related Acts & Talent</h1>
            <p>The following music artists have performed, written or collaborated with Lord Toph, or have been developed and/or produced by Lord Toph. Each artist has contributed their unique talents and creative vision, enriching the musical tapestry that defines Lord Toph's expansive career. Through these collaborations, Lord Toph has forged enduring artistic relationships that have resulted in a diverse and dynamic body of work, spanning multiple genres and resonating with audiences around the world.</p>
            <div className={"artists"}>
                Damen Samuel (Folk/Acoustic/Soul) Melbourne, Australia/Cologne Germany<br />
                Ebony Anne Isaac (Adult Contemporary/R&B/Pop) New York<br />
                SnowFlake Black (Hip-Hop/Rap) New York/Tel Aviv, Israel<br />
                The Tony Mazza Project (Rock/Blues) New Jersey<br />
                Kyle Whitney (Pop/Soul) Los Angeles, CA<br />
                Cris Vela (R&B/Soul)<br />
                TriQi Davis (Pop/Soul/Rock/Funk) New York<br />
                Joey Coca (Rock/Blues/Soul/Funk) Little Rock, AR<br />
                Luscious Spiller (Soul/Blues/Funk/Rock) Clarksdale, MS<br />
                Cinnamon Sticks (Spoken Word) Los Angeles, CA<br />
                Claire Cave (Adult Contemporary) Yorkshire, UK<br />
                Foxy Foxes (Pop/Dance) New York/Japan<br />
                Peek-a-Boo & the Funcats (Brit Pop/'80s Pop) New York<br />
                Claire Cave (Adult Contemporary/Pop) Yorkshire, England<br />
                Katie Redman (Adult Contemporary/Soft Rock/Gospel) Bella Vista, AR <br />
                <br />
            </div>
        </div>
    );
};

export default AboutRelatedActsPage;
