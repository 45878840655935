import React from 'react';



const Rain_video = () => (
    <div className="content">
        <h1>The Rain & Whispers Video Project</h1>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/4_the_rain_and_whispers_video_project/The Rain & Whispers Video Project - Page 1.png" alt={"The Rain and Whispers Video Page 1"}/>
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/4_the_rain_and_whispers_video_project/The Rain & Whispers Video Project - Page 2.png" alt={"The Rain and Whispers Video Page 2"}/>
        </div>

<div className={"about-text"}>

    </div>
    </div>
);


export default Rain_video;