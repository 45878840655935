// ServicesPage.js
import React from 'react';
import hero from './img/blackandwhiteno-1.jpg'

const ServicesPage = () => (
    <div className="content servicespage">
        <img className="hero-img animate__animated animate__fadeIn" src={hero} alt={"Black and White Number 1"} />

        <h1>Services Page</h1>

        <h2>Musical Services</h2>

        <h3>Songwriting and Composition</h3>
        <p>Harnessing his extensive catalog and experience, Lord Toph offers songwriting and composition services. He creates original pieces tailored to your specifications, whether it’s for an album, film score or special project. His ability to blend genres and evoke emotion through music, makes his compositions truly unique.</p>

        <h3>Artist Development</h3>
        <p>Receive comprehensive guidance and support from Lord Toph in honing your craft, building your brand, and navigating the music industry. This service includes personalized mentorship, career strategy planning, vocal coaching, songwriting workshops, image consulting and branding advice. Lord Toph leverages his extensive experience to help emerging artists establish a solid foundation, develop their unique sound and create a marketable identity that resonates with audiences.</p>

        <h3>Video Direction</h3>
        <p>Collaborate with Lord Toph to create captivating and visually stunning music videos that complement your music. This service encompasses the entire video production process, from conceptualization and storyboard creation to directing, filming, and post-production. Lord Toph's artistic vision and attention to detail ensure that each music video not only enhances the song but also tells a compelling story, engaging viewers and elevating your overall artistic presentation.</p>

        <h3>Music Production</h3>
        <p>Lord Toph provides full-scale music production services, from initial concept to final mix. His expertise covers various genres, ensuring a polished and professional sound. Collaborate with Lord Toph to bring your musical vision to life with the highest production standards.</p>

        <h3>Music Panels, Podcast Talks, and Expos</h3>
        <p>Engage with the music industry through Lord Toph’s music panels, podcast talks, and expos. As a seasoned artist and producer, Lord Toph shares his insights and experiences, offering valuable knowledge to aspiring musicians and industry professionals. These events provide a platform for learning, networking and exploring the dynamic world of music.</p>

        <h2>Artistic Services</h2>

        <h3>Commissioned Artworks</h3>
        <p>Lord Toph offers bespoke art commissions, tailored to your personal or corporate needs. Whether it’s a unique portrait, a custom piece for your home or an evocative mural, Lord Toph's creativity and expertise will bring your vision to life. His style, rooted in Empathicism, ensures that every piece is not just seen but felt.</p>

        <h3>Art Consultation</h3>
        <p>With years of experience in the art world, Lord Toph provides art consultation services to help you curate your collection or plan an exhibition. His insights and guidance can elevate your artistic endeavors, ensuring each piece finds its perfect place.</p>

        <h3>Fine Art Leasing</h3>
        <p>Enhance your commercial or private space with Lord Toph’s fine art leasing services. Ideal for businesses, private collections, and open house showings, this service allows you to showcase stunning artworks without the commitment of permanent ownership. Elevate your environment with rotating pieces that inspire and impress.</p>

        <h2>Literary Services</h2>

        <h3>Custom Writing</h3>
        <p>Lord Toph's literary prowess spans multiple genres. He offers custom writing services for various needs, including personalized stories, ghostwriting, and editorial work. Whether you need a compelling narrative or a polished manuscript, his expertise as a published author guarantees top-quality results.</p>

        <h3>Creative Workshops</h3>
        <p>Join Lord Toph for creative writing workshops designed to unlock your storytelling potential. These sessions, tailored to different skill levels, provide invaluable insights into character development, plot structuring, and refining your literary voice.</p>

        <h3>Public and Private Book Readings</h3>
        <p>Experience the magic of literature with Lord Toph's public and private book readings. These events are tailored to audiences of all sizes and offer a unique opportunity to hear the author bring his stories to life. Ideal for book clubs, educational institutions, and special events, these readings create an intimate connection between the author and the audience.</p>

        <h2>Design Services</h2>

        <h3>Graphic Design</h3>
        <p>From album covers to promotional materials, Lord Toph’s graphic design services cater to all your visual communication needs. His eye for detail and creative flair ensures that your design stands out and resonates with your audience.</p>

        <h3>Branding and Identity</h3>
        <p>Lord Toph offers comprehensive branding services, helping you create a cohesive and compelling brand identity. His approach integrates visual elements, messaging, and overall brand strategy to effectively communicate your values and connect with your audience.</p>

        <p>Discover how Lord Toph's multifaceted expertise can transform your artistic, literary, musical and design projects.</p>
        <p>Contact us today to learn more about these services and begin your creative journey, with Lord Toph.</p>
<br />
    </div>
);

export default ServicesPage;
