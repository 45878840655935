import React from 'react';


const Bliss_performance = () => (
    <div className="content">
        <h1>Bliss & the Masquerade Performance</h1>

        <div className="slide">
            <img src={"https://lordtophstorage.blob.core.windows.net/web/projects/5_bliss_performance/16. Bliss & the Masquerade Halloween Event Invitation.png"} alt={"Bliss and the Masquerade Invitation"}/>
        </div>


<div className={"about-text"}>Purchase tickets here and secure your spot for this unforgettable evening:</div>
    <div><a href={"https://stubs.net/tickets/6496/bliss-and-the-masquerade-performance-and-listening-party"}>Get Tickets Now!</a></div>
        <div>Don’t miss out — reserve your place today!</div>

<p>&nbsp;</p>
    </div>
);


export default Bliss_performance;