import React from 'react';



const Bliss_Grammy = () => (
    <div className="content">
        <div className={"slide"}>
        <h1>Bliss & the Masquerade: The Grammy Journey</h1>
        </div>
            <div className={"slide"}>
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/6_bliss_grammy/The Bliss & the Masquerade Grammy Campaign Project - Page 1.png" alt={"Bliss and the Masquerade Grammy Campaign Project Page 1"} />
            </div>
            <div className={"slide"}>
                <img src="https://lordtophstorage.blob.core.windows.net/web/projects/6_bliss_grammy/The Bliss & the Masquerade Grammy Campaign Project - Page 2.png" alt={"Bliss and the Masquerade Grammy Campaign Project Page 2"} />
            </div>
            <div className={"slide"}>
                <img src="https://lordtophstorage.blob.core.windows.net/web/projects/6_bliss_grammy/The Bliss & the Masquerade Grammy Campaign Project - Page 3.png" alt={"Bliss and the Masquerade Grammy Campaign Project Page 3"} />
            </div>


    </div>
);


export default Bliss_Grammy;