// AboutLiteraturePage.js
import React from 'react';
import Hero from "./img/Literature - Website Image.png"


const AboutLiteraturePage = () => (
    <div className="content literature">
            <img className="hero-img animate__animated animate__fadeIn" src={Hero} alt={"Lord Toph Literature"}/>
            <h1>Lord Toph Literary Catalog</h1>
        <h2>Literature</h2>

        <div className="item">“Poetry for Yoonsil” – 2014 (A collection of 33 poetic compositions)</div>
        <div className="item">“Five Old Wives’ Tales” – 2014 (Poetry/Short Story)</div>
        <div className="item">“Reveries of Romance & Sentiment” – 2015 (A collection of 33 poetic compositions)</div>
        <div className="item">“Prose for the Unrequited” – 2019 (A collection of 33 poetic compositions)</div>
        <div className="item">“Dillinger Comes to Dinner” – 2019 (Novella)</div>
        <div className="item">“The Bastard of Indulgence” – 2019 (Poetry/Short Story)</div>
        <div className="item">“Heaven Tea & Sweet Potato Pie” – 2019 (Poetry/Short Story)</div>
        <div className="item">“Chae Chaplin the Vampire” – Spring/Summer, 2021 release (Graphic Novel)</div>
        <div className="item">“Dear Miss Anne Marie” – 2023 (Poetic Short Story)</div>
        <div className="item">“Our Little Red Wagon” – 2023 (Novella)</div>
        <div className="item">“The Spinster of Contempt” – 2023 (Poetic Short Story)</div>
        <div className="item">“Concise, Candid Confessions of the Grisly Grotesque” – 2023 (Novelette)</div>
        <div className="item">“Sayings Quotes & Phrases” – 2023 (Prose/Poetry)</div>
        <div className="item">“Noctvrnal Passages” – 2023 (Dark, Gothic Poetry)</div>
        <div className="item">“The Nymphs of Noremil” – 2023 (Poetic Erotica)</div>
        <div className="item">“Pretty Little Black Girl” – 2024 (A Poetic Monologue)</div>
        <div className="item">“Some Strange Soup” – 2024 release (Science Fiction/Novella)</div>
        <div className="item">“The Pauper & the Sylph” – Winter, 2024 release (Novelette)</div>
        <h2>Children’s Literature</h2>

        <div className="item">“Fuzzy McKenzie” – 2014 (Picture Book)</div>
        <div className="item">“Crusty Bigglebones” – 2014 (Picture Book)</div>
        <div className="item">“Marvin in the Kooky Spooky House” – 2014 (Picture Book)</div>
        <div className="item">“The Sweet Eaters” – 2015 (Picture Book)</div>
        <div className="item">“The Girl Who Could Not Sneeze” – 2015 (Novella)</div>
        <div className="item">“Lou Says… ‘Who Says?’” – 2019 (Picture Book)</div>
        <div className="item">“Merthodokalis Monk & the Haunted Hat” – 2023 (Picture Book)</div>
        <div className="item">“Penelope Pettleby’s Cones, Rings, Zap Machines & Other Soothsayer Gadgets” – Winter, 2024 release (Picture Book)</div>
        <div className="item">“Heather & the Harlequin Horse” – Spring, 2025 release (Picture Book)</div>
        <div className="item">“Lox & Leonard” – Summer/Fall, 2025 release (Novel)</div>
            <br />
    </div>
);

export default AboutLiteraturePage;
