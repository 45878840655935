// AboutDiscographyPage.js
import React from 'react';
import Hero from './img/Lord Toph Discography Image.png'


const AboutDiscographyPage = () => (
    <div className="content discography">
            <img className="hero-img animate__animated animate__fadeIn" src={Hero} alt={"Lord Toph Discography"}/>

            <h1>Lord Toph Discography</h1>
        <h2>Albums</h2>
        <div className="item">"Under the Sun" – 1999; To be re-released (Pop/Mainstream)</div>
        <div className="item">"The Hvnger" – 2008 (Gothic Rock)</div>
        <div className="item">"An Evening Soiree" – 2015 (Contemporary Instrumental)</div>
        <div className="item">"Articles in the Cellar" – 2015 (Rock/Soul)</div>
        <div className="item">"In A World of Soundness" – 2016 (Instrumental/Soundtrack/New Age)</div>
        <div className="item">“Marvin in the Kooky Spooky House” – 2016 (Children’s Music)</div>
        <div className="item">“Songs for a Doe” – 2019 (Pop/Adult Contemporary/Soul)</div>
        <div className="item">“Nacreous” – Spring, 2020 release (Instrumental/Ambient/Soundtrack)</div>
        <div className="item">"Peek-a-Boo & the Funcats" – Winter, 2024 release (Brit-Pop)</div>
        <div className="item">"Bliss & the Masquerade" – In Production (Pop/Funk/Soul; A double album music collection of songs ranging over a decade)</div>
        <div className="item">"Remakes & Tributes" – In Production (Pop/Soul)</div>
        <div className="item">“When I Come to Paris” – Spring/Summer, 2025 release (Contemporary Jazz/Adult Contemporary)</div>
        <div className="item">"The Other Side of the Mountain" – Spring/Summer, 2025 (Gospel/Spiritual)</div>
        <h2>Singles</h2>

        <div className="item">“Love Don’t Play Fair” – 2016 (Adult Contemporary/Pop)</div>
        <div className="item">“When There’s a Will” (ft. Tony Mazza) – 2016 (Alternative/Pop)</div>
        <div className="item">“기다린 만큼, 더” (“All That I Wanted, More”) (Remake) – 2018 (Pop/Korean Pop)</div>
        <div className="item">“China Girl” (ft. SnowFlake Black) (Remake) – 2018 (Pop)</div>
        <div className="item">“The Other Side of the Mountain” – 2018 (Gospel/Spiritual)</div>
        <div className="item">“No Ordinary Love” (Remake) – 2018 (Pop/Soul)</div>
        <div className="item">“Incantations of Melody” – 2018 (Adult Contemporary)</div>
        <div className="item">“Blues on Christmas Eve” – 2018 (Holiday/Seasonal/Adult Contemporary)</div>
        <div className="item">“A Little Something for Everyone” (Narration by Ross Allen) – 2018 (Holiday/Seasonal)</div>
        <div className="item">“Big Christmas” – 2018 (Holiday/Seasonal)</div>
        <div className="item">“Close to You” (Remake) – 2019 (Adult Contemporary/Pop)</div>
        <div className="item">“Ribbon on a Box” – 2019 (Pop)</div>
        <div className="item">“Bread for the Soul” – 2019 (Worldbeat/Hip-Hop)</div>
        <div className="item">“I’m Here for You” – 2020 release (Pop/Adult Contemporary)</div>
        <h2>Collaborations</h2>

        <div className="item">"Christmas Town" (Single with Ross Allen) – 2013 (Holiday/Seasonal)</div>
        <div className="item">"Conceive" (Single with SnowFlake Black) – 2014 (Hip-Hop/Pop)</div>
        <div className="item">"Live the Life" (Single with Damen Samuel) – 2018 (Adult Contemporary/Pop)</div>
        <div className="item">"Never Keep Myself from You" (Single with Ebony Anne Isaac) – to-be-re-released (Adult Contemporary/R&B)</div>
        <div className="item">“When There’s A Will” (Single with Tony Mazza) – 2016 (Adult Contemporary/Pop)</div>
    <br />
    </div>
);

export default AboutDiscographyPage;
