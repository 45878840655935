import React from 'react';
import bumpfront from "./img/projects/bump_and_ride/1. Bump & Ride Presentation Cover.png";
import bump1 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 1.png";
import bump2 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 2.png";
import bump3 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 3.png";
import bump4 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 4.png";
import bump5 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 5.png";
import bump6 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 6.png";
import bump7 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 7.png";
import bump8 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 8.png";
import bump9 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 9.png";
import bump10 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 10.png";
import bump11 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 11.png";
import bump12 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 12.png";
import bump13 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 13.png";
import bump14 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 14.png";
import bump15 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 15.png";
import bump16 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 16.png";
import bump17 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 17.png";
import bump18 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 18.png";
import bump19 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 19.png";
import bump20 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 20.png";
import bump21 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 21.png";
import bump22 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 22.png";
import bump23 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 23.png";
import bump24 from "./img/projects/bump_and_ride/Bump & Ride - Pg. 24.png";
import bumpback from "./img/projects/bump_and_ride/Bump & Ride Presentation Back Cover.png";

const BumpandRide = () => (
    <div className="content">
        <h1>Bump & Ride</h1>

        <div className="slide">
            <img src="https://lordtoph.com/static/media/1.%20Bump%20&%20Ride%20Presentation%20Cover.eda5ffb27eb9e48e26f6.png" alt="Bump & Ride Presentation Cover" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%201.9d0b03e0974ed6139dd3.png" alt="Bump & Ride Page 1" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%202.4f7b78c558296acdb295.png" alt="Bump & Ride Page 2" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%203.946bf69d2e8b18054c43.png" alt="Bump & Ride Page 3" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%204.811a0740903d5272fd07.png" alt="Bump & Ride Page 4" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%205.0beef7517656e089e041.png" alt="Bump & Ride Page 5" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%206.a9c256e1cb886d81bca1.png" alt="Bump & Ride Page 6" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%207.170cd2d653eae29a88ab.png" alt="Bump & Ride Page 7" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%208.85536a2e5a8735238e83.png" alt="Bump & Ride Page 8" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%209.00891a4f263e09bf6ead.png" alt="Bump & Ride Page 9" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2010.4ac136a0340f1daa2e46.png" alt="Bump & Ride Page 10" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2011.7efb694e5e7eab0d1613.png" alt="Bump & Ride Page 11" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2012.b832129d49ce34eea56d.png" alt="Bump & Ride Page 12" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2013.b70984af19660eef88c1.png" alt="Bump & Ride Page 13"/>
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2014.89768a8a3ef6c4271f5e.png" alt="Bump & Ride Page 14" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2015.ed459ff7dfdd31c41361.png" alt="Bump & Ride Page 15" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2016.af929e7209062a482ba3.png" alt="Bump & Ride Page 16" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2017.20dfa624ea514112d504.png" alt="Bump & Ride Page 17" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2018.1c75a0d27103ea0752ff.png" alt="Bump & Ride Page 18" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2019.c82e35ae9db0cfcaf468.png" alt="Bump & Ride Page 19" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2020.7911342a267fa9f4e1af.png" alt="Bump & Ride Page 20" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2021.82f27f917174dbb4c922.png" alt="Bump & Ride Page 21" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2022.e2912e8e0b841755d175.png" alt="Bump & Ride Page 22" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2023.d70d2c23c8e0f9ffb354.png" alt="Bump & Ride Page 23" />
        </div>
        <div className="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20-%20Pg.%2024.49c7b4df40c78f1b3908.png" alt="Bump & Ride Page 24" />
        </div>
        <div class="slide">
            <img src="https://lordtoph.com/static/media/Bump%20&%20Ride%20Presentation%20Back%20Cover.934be8ea34b397ada602.png" alt="Bump & Ride Presentation Back Cover" />
        </div>



        <p className={"hidden"}>
            <a href={bumpfront}>Bump & Ride Front Cover</a>
            <a href={bump1}>Bump & Ride Page 1</a>
            <a href={bump2}>Bump & Ride Page 2</a>
            <a href={bump3}>Bump & Ride Page 3</a>
            <a href={bump4}>Bump & Ride Page 4</a>
            <a href={bump5}>Bump & Ride Page 5</a>
            <a href={bump6}>Bump & Ride Page 6</a>
            <a href={bump7}>Bump & Ride Page 7</a>
            <a href={bump8}>Bump & Ride Page 8</a>
            <a href={bump9}>Bump & Ride Page 9</a>
            <a href={bump10}>Bump & Ride Page 10</a>
            <a href={bump11}>Bump & Ride Page 11</a>
            <a href={bump12}>Bump & Ride Page 12</a>
            <a href={bump13}>Bump & Ride Page 13</a>
            <a href={bump14}>Bump & Ride Page 14</a>
            <a href={bump15}>Bump & Ride Page 15</a>
            <a href={bump16}>Bump & Ride Page 16</a>
            <a href={bump17}>Bump & Ride Page 17</a>
            <a href={bump18}>Bump & Ride Page 18</a>
            <a href={bump19}>Bump & Ride Page 19</a>
            <a href={bump20}>Bump & Ride Page 20</a>
            <a href={bump21}>Bump & Ride Page 21</a>
            <a href={bump22}>Bump & Ride Page 22</a>
            <a href={bump23}>Bump & Ride Page 23</a>
            <a href={bump24}>Bump & Ride Page 24</a>
            <a href={bumpback}>Bump & Ride Back Cover</a>


        </p>
<div className={"about-text"}>

    </div>
    </div>
);


export default BumpandRide;