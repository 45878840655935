// In src/blogPosts/blogPosts.js
import image2 from '../img/blog/bread_for_the_soul.webp'
import image3 from '../img/blog/an_evening_soiree.webp'
import image4 from '../img/blog/in_a_world_of_soundness.webp'
import image5 from '../img/blog/nacreous.webp'
import image6 from '../img/blog/file.baby@knowbots.org-1717358974356-songs_for_a_doe.webp'
import image7 from '../img/blog/ribbon_on_a_box.webp'
import image8 from '../img/blog/whats_going_on.webp'
import image9 from '../img/blog/Not_to_Worry.webp'
import image10 from '../img/blog/peek-a-boo_and_the_funcats_blog_hero.jpg'
import image11 from '../img/blog/Incantations_of_Melody.jpg'
import image12 from '../img/blog/Foolish_Games.jpg'

const blogPosts = [
    {
        id: 15,
        urlkey:'pressrelease-bliss',
        title: 'New York Artist Lord Toph Brings Bliss & the Masquerade" to Northwest Arkansas',
        author: 'Press Agent',
        date: 'September 14, 2024',
        image: image12, // Add an appropriate image if available
        imagelink: '',
        alt: 'Press Release',
        description: 'Lord Toph, a multifaceted artist and Grammy Award candidate, is heading to Northwest Arkansas to launch his highly anticipated double album, Bliss & the Masquerade.',
        component: () => import('./BlogPost15'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 14,
        urlkey:'songwriting-workshop',
        title: 'Unleash Your Inner Songwriter: Join Lord Toph\'s Songwriting Workshop!',
        author: 'Beth Abbot',
        date: 'June 25, 2024',
        image: image11, // Add an appropriate image if available
        imagelink: '',
        alt: 'Incantations of Melody',
        description: 'Join Lord Toph for an exclusive songwriting workshop that promises to ignite your creative spark and elevate your musical prowess.',
        component: () => import('./BlogPost14'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 13,
        urlkey:'peek-a-boo',
        title: 'Lord Toph\'s "Peek-a-Boo & the Funcats" Album Soon to be Released',
        author: 'Beth Abbot',
        date: 'June 7, 2024',
        image: image10,
        imagelink: 'https://www.amazon.com/-/es/Lord-Toph/dp/B07KFG3WFH',
        alt: 'SOON TO BE RELEASED: Peek-a-Boo and the Funcats',
        description: 'Get ready to be transported back to the vibrant era of \'80s Brit-Pop with Lord Toph\'s upcoming album, \'Peek-a-Boo & the Funcats,\' a nostalgic musical journey you won\'t want to miss.',
        component: () => import('./BlogPost13'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 11,
        urlkey:'dillinger',
        title: 'Dillinger Comes to Dinner',
        author: 'Phil Howard',
        date: 'June 4, 2024',
        image: image8,
        imagelink: 'https://open.spotify.com/album/3OemA6wu0CmJMlFHE742EO',
        alt: 'What\'s Going On',
        description: 'This untold tale offers a glimpse into a brief but remarkable encounter between John Dillinger and the Muldrow family in Little Rock, Arkansas, before his return to Chicago, Illinois.',
        component: () => import('./BlogPost11'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 10,
        urlkey:'kosmovinyl',
        title: 'A Cool Clash of Creativity: Lord Toph\'s Candid Conversation with Kosmo Vinyl at the Grammy Museum',
        author: 'Beth Abbot',
        date: 'June 2, 2024',
        image: image7,
        imagelink: 'https://open.spotify.com/track/6cFVrtm7XS44cRS8hE5zxr',
        alt: 'Ribbon on a Box by Lord Toph',
        description: 'In a meeting of musical minds that transcends time and space, Lord Toph recently engaged in a candid conversation with none other than Kosmo Vinyl, the former manager of the legendary punk rock group, The Clash.',
        component: () => import('./BlogPost10'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 12,
        urlkey:'chatgpt',
        title: 'A Morning Chat with ChatGPT Confirms…',
        author: 'Lord Toph and ChatGPT',
        date: 'May 28, 2024',
        image: image9,
        imagelink: 'https://open.spotify.com/album/1Q8I81PGBdnhyvcNvmWDaY',
        alt: 'Marvin in the Kooky Spooky House',
        description: 'Recently, a simple discvssion tvrned into nearly a heated debate with someone who can be qvite obstinate in demeanor.',
        component: () => import('./BlogPost12'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 9,
        urlkey:'debater',
        title: 'The Adamant Debater: A Lesson in Linguistic Flexibility',
        author: 'Phil Howard',
        date: 'May 27, 2024',
        image: image5,
        imagelink: 'https://open.spotify.com/album/3D6obaZIz8omVoP9pDupRY?si=6QetrNVZSKaIS5xrtZg44g',
        alt: 'Nacreous by Lord Toph',
        description: 'A debate between Lord Toph and the Adamant Debater over the nuances of two similar phrases highlights the importance of linguistic flexibility and open-mindedness.',
        component: () => import('./BlogPost9'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 8,
        urlkey:'vs',
        title: 'What\'s with the V\'s?!?',
        author: 'Tracie Blake',
        date: 'May 20, 2024',
        image: image6,
        imagelink: 'https://open.spotify.com/album/27LXXh6h7EECS9ZvGLjeUH',
        alt: 'Songs for a Doe by Lord Toph',
        description: 'The importance of linguistic flexibility through a debate over the nuances of similar phrases.',
        component: () => import('./BlogPost8'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 7,
        urlkey:'restoration',
        title: 'A Random Thought, During a Time of Restoration, in a New Place',
        author: 'Lord Toph',
        date: 'May 12, 2024',
        image: image4,
        imagelink: 'https://open.spotify.com/album/4tjWjf9q2s6MDtqFK94UGb',
        alt: 'In a World of Soundness by Lord Toph',
        description: 'A Random Thought, During a Time of Restoration, in a New Place',
        component: () => import('./BlogPost7'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 6,
        urlkey:'lockedinthemachine',
        title: 'The Chorus for “Locked in the Machine"',
        author: 'Lord Toph',
        date: 'April 234, 2024',
        image: image3,
        imagelink: 'https://open.spotify.com/album/5KcovCEvwFnWFPBBMzMk5k',
        alt: 'An Evening Soiree by Lord Toph',
        description: 'Locked in the Machine–the chorus.',
        component: () => import('./BlogPost6'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 5,
        urlkey:'frommydream',
        title: 'Being Spoken from My Dream',
        author: 'Lord Toph',
        date: 'April 15, 2024',
        image: image2,
        imagelink: 'https://open.spotify.com/album/4IJyM4Tu0ivyS1M6A8RqI5?si=sf_3OaF7SiKlZj5bAwkVng',
        alt: 'Bread for the Soul by Lord Toph, Damen Samuel and Snowflake Black',
        description: 'Being Spoken from My Dream',
        component: () => import('./BlogPost5'), // Assuming BlogPost1.js returns a default React component
    },
];

export default blogPosts;
