import React from 'react';

const More_video = () => (
    <div className="content">
        <h1>More & More</h1>

        <div className="slide">
            <a href={"https://mailchi.mp/3b7ac3a022be/more-and-more-free-download"}><img src="https://lordtophstorage.blob.core.windows.net/web/projects/2_the_more_and_more_video_project/The More & More Video Project - Page 1.png" alt={"The More and More Video Page 1"}/></a>
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/2_the_more_and_more_video_project/The More & More Video Project - Page 2.png" alt={"The More and More Video Page 2"}/>
        </div>


<div className={"about-text"}>

    </div>
    </div>
);


export default More_video;