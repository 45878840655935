import React from 'react';

const Bliss_Media_Kit = () => (
    <div className="content">
        <h1>Bliss & the Masquerade Media Kit</h1>

        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/1.%20Bliss%20&%20the%20Masquerade%20Opening%20Page%20with%20Album%20Cover.png" alt="Bliss and the Masquerade Media Kit Page 1" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/2.%20Bliss%20&%20the%20Masquerade%20Song%20List.png" alt="Bliss and the Masquerade Media Kit Page 2" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/3.%20Bliss%20&%20the%20Masquerade%20Album%20Credits.png" alt="Bliss and the Masquerade Media Kit Page 3" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/4.%20Bliss%20&%20the%20Masquerade%20More%20&%20More%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 4" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/5.%20Bliss%20&%20the%20Masquerade%20If%20I%20Was%20Your%20Man%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 5" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/6.%20Bliss%20&%20the%20Masquerade%20In%20Deep%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 6" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/7.%20Bliss%20&%20the%20Masquerade%20Time%20I%20Tell%20You%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 7" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/8.%20Bliss%20&%20the%20Masquerade%20Sway%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 8" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/9.%20Bliss%20&%20the%20Masquerade%20Soul%20Food%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 9" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/10.%20Bliss%20&%20the%20Masquerade%20Back%20of%20a%20Cadillac%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 10" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/11.%20Bliss%20&%20the%20Masquerade%20Night%20Returns%20the%20Day%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 11" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/12.%20Bliss%20&%20the%20Masquerade%20You%20Are%20the%20One%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 12" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/13.%20Bliss%20&%20the%20Masquerade%20Psychedelic%20Dream%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 13" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/14.%20Bliss%20&%20the%20Masquerade%20Whatever%20the%20Reason%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 14" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/15.%20Bliss%20&%20the%20Masquerade%20Unicorn%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 15" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/16.%20Bliss%20&%20the%20Masquerade%20Halloween%20Event%20Invitation.png" alt="Bliss and the Masquerade Media Kit Page 16" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/17.%20Bliss%20&%20the%20Masquerade%20Halloween%20Event%20Playlist.png" alt="Bliss and the Masquerade Media Kit Page 17" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/18.%20The%20Monté%20CrisToph%20Drink%20Promo%20Page.png" alt="Bliss and the Masquerade Media Kit Page 18" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/19.%20Bliss%20&%20the%20Masquerade%20Dedication%20&%20Special%20Thanks.png" alt="Bliss and the Masquerade Media Kit Page 19" />
        </div>
        <div className="slide">
            <img src="https://lordtophstorage.blob.core.windows.net/web/projects/1_bliss_media_kit/20.%20Bliss%20&%20the%20Masquerade%20Media%20Kit%20Social%20Links.png" alt="Bliss and the Masquerade Media Kit Page 20" />
        </div>

        <div className={"about-text"}>
        </div>
    </div>
);

export default Bliss_Media_Kit;
